import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';

function AddWorkingHour({ api, user_data }) {

    const [start_time, setStart_time] = useState('');
    const [end_time, setEnd_time] = useState('');
    const [date, setDate] = useState('');
    const [totalHour, setTotalHour] = useState('');
    const [validated, setValidated] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [disableTo, setDisableTo] = useState(false);
    const [existTimeError, setExistTimeError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const calculateTotalHour = () => {
        if (!start_time || !end_time) {
            return "";
        }
        const start = new Date(`2000-01-01T${start_time}:00`);
        const end = new Date(`2000-01-01T${end_time}:00`);
        let diff = Math.abs(end - start);
        const hours = Math.floor(diff / 3600000).toString().padStart(2, "0");
        diff -= hours * 3600000;
        const minutes = Math.floor(diff / 60000).toString().padStart(2, "0");
        setTotalHour(hours + ':' + minutes);
    };

    useEffect(() => {
        const today = new Date();
        const day = today.getDate().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear();
        const todayDate = `${year}-${month}-${day}`;
        setDate(todayDate);
    }, []);

    useEffect(() => {
        calculateTotalHour();
        const startTimeMoment = moment(start_time, 'HH:mm');
        const endTimeMoment = moment(end_time, 'HH:mm');
        if (start_time && end_time) {
            if (endTimeMoment.isBefore(startTimeMoment)) {
                setEndDateError(true);
                setDisableTo(true);
                setTotalHour('00:00');
            } else {
                setEndDateError(false);
            }
        }
    }, [start_time, end_time, disableTo]);

    const AddWorkingHour = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid) {
            await api.post(`/addWorkingHour`, {
                start_time: start_time,
                end_time: end_time,
                date: date,
                total_hour: totalHour,
            })
                .then(() => {
                    navigate('/working-hour');
                })
                .catch((error) => {
                    console.log("error", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setValidated(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (user_data._id, date) {
            api.post(`/checkHour`, {
                user_id: user_data._id,
                date: date
            }).then((response) => {
                const __workingHours = response.data.workingHourData;
                const exist = __workingHours.some((workingHour) => {
                    return (
                        start_time >= workingHour.start_time &&
                        start_time < workingHour.end_time
                    );
                });
                setExistTimeError(exist);
            }).catch((error) => {
                console.log('error', error);
            })
        }
    }, [date, start_time]);

    useEffect(() => {
        if (!start_time || existTimeError) {
            setDisableTo(true);
        }
        else {
            setDisableTo(false);
        }
    }, [disableTo, start_time, existTimeError]);

    return (
        <>
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Working Hour</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={e => AddWorkingHour(e)} noValidate validated={validated}>
                                <Row>
                                    <Col sm="12" lg="3">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">From</Form.Label>
                                            <Form.Control
                                                type="time"
                                                id="title"
                                                onChange={e => setStart_time(e.target.value)}
                                                style={existTimeError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                required
                                            />
                                            {existTimeError && (
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    You already added this time's working hour
                                                </Form.Control.Feedback>
                                            )}
                                            <Form.Control.Feedback type="invalid">
                                                This field is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="3">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="permission_description">To</Form.Label>
                                            <Form.Control
                                                type="time"
                                                id="project_description"
                                                onChange={e => setEnd_time(e.target.value)}
                                                required
                                                style={endDateError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                disabled={disableTo}
                                            />
                                            {endDateError && (
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    End time must be less than start time
                                                </Form.Control.Feedback>
                                            )}
                                            <Form.Control.Feedback type="invalid">
                                                This field is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="3">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="permission_description">Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                id="project_description"
                                                onChange={e => setDate(e.target.value)}
                                                required
                                                value={date}
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                This field is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="3">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">Total Hour</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="title"
                                                value={totalHour}
                                                onChange={e => setTotalHour(e.target.value)}
                                                required
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                This field is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="12" className='d-flex justify-content-center mt-2'>
                                        <Button
                                            type="submit"
                                            variant="btn btn-primary me-2"
                                            disabled={loading}
                                        >
                                            {loading ? 'Submitting...' : 'Submit'}
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="btn btn-danger"
                                            onClick={() => navigate('/working-hour')}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default AddWorkingHour;
