import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';

function ChangePassword({ api, user_id }) {
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confPass, setConfPass] = useState('');
    const [oldPassError, setOldPassError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const ChangePassword = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        setLoading(true);
        if (isValid && newPass === confPass) {
            api.post(`/change_password/${user_id}`, {
                oldpassword: oldPass,
                newpassword: newPass,
                cpassword: confPass,
            }).then((response) => {
                // if (response.data.changePassStatus === false) {
                //     setOldPassError(true);
                //     setValidated(true)
                // }
                // else {
                //     navigate('/');
                // }
            }).catch((error) => {
                setLoading(false);
            })
        } else {
            setValidated(true)
            setLoading(false);
        }

    }


    const [passType, setPassType] = useState('password');
    const showpass = () => {
        setPassType(passType === "password" ? "text" : "password");
    };
    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Change Password</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form id="form-wizard1" onSubmit={e => ChangePassword(e)} noValidate validated={validated}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="role">Old Password: <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type={passType} id="role_name" onChange={e => setOldPass(e.target.value)} required
                                            style={oldPassError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                        />
                                        <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                                        {oldPassError &&
                                            <Form.Control.Feedback className='text-danger'>Old Password didn't match</Form.Control.Feedback>
                                        }

                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="role">New Password: <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type={passType} id="role_name" onChange={e => setNewPass(e.target.value)} required
                                            pattern="^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=]).*$"
                                        />
                                        <Form.Control.Feedback type="invalid">Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="role">Confirm Password: <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type={passType} id="role_name" onChange={e => setConfPass(e.target.value)}
                                            style={confPass.length > 0 && newPass !== confPass ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                            required />
                                        <Form.Control.Feedback type="invalid">Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character</Form.Control.Feedback>
                                        {confPass.length > 0 &&
                                            newPass !== confPass &&
                                            <Form.Control.Feedback className='text-danger'>Confirm password must be same new password</Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                    <Col lg="12" className="d-flex justify-content-end">
                                        <Form.Check className="form-check mb-3">
                                            <Form.Check.Input type="checkbox" id="customCheck1" onClick={() => showpass()} />
                                            <Form.Check.Label htmlFor="customCheck1">Show Password</Form.Check.Label>
                                        </Form.Check>
                                    </Col>
                                    <Button type="submit" disabled={loading} variant="btn btn-primary">{loading ? 'Submiting...' : 'Submit'}</Button>{' '}
                                    <Button type="button" variant="btn btn-danger" onClick={() => navigate('/')}>cancel</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    )
}

export default ChangePassword
