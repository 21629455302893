
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Nav, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import '../../assets/css/customAlert.css'
import Card from '../../components/Card'
import { showToast } from '../../plugins/toastUtils';

const EmployeePermission = ({ api }) => {

    const { id } = useParams();
    const themeMode = useSelector((state) => state.mode.scheme);
    let navigate = useNavigate();
    const [permission, setPermission] = useState([])
    const [existpermission, setExistpermission] = useState([]);
    const [permissionModules, setPermissionModules] = useState([]);
    const [roleData, setRoleData] = useState([])
    const [loading, setLoading] = useState(false);
    const [unChek, setUnChek] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const permissiondata = () => {
        api.get(`userPermission/${id}`).then((response) => {
            if (response.data !== undefined) {
                setRoleData(response.data.roledatas)
                setPermission(response.data.allPermmission);
                setExistpermission(response.data.existPermissions);
                setPermissionModules(response.data.permissionModuleData);
            }
        }).catch((error) => {
            showToast(error.response.data.errors, 'error');
        });
    }

    useEffect(() => {
        permissiondata();
        return () => {
        };
    }, []);

    useEffect(() => {
        if (existpermission.length === permission.length) {
            setUnChek(true);
        } else {
            setUnChek(false);
        }
        return () => {

        };
    }, [existpermission]);

    const handlePermissionChange = (e) => {
        const permissionId = e.target.value;
        const isChecked = e.target.checked;

        if (isChecked) {
            setExistpermission([...existpermission, permissionId]);
            setSelectAll(existpermission.length + 1 === permission.length);
        } else {
            const newPermissions = existpermission.filter((id) => id !== permissionId);
            setExistpermission(newPermissions);
            setSelectAll(false);
        }
    };

    const handleMultiSelect = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectAll(true);
            const allPermissionIds = permission.map((p) => p._id);
            setExistpermission(allPermissionIds);
            setUnChek(true);
        } else {
            setSelectAll(false);
            setExistpermission([]);
            setUnChek(false);
        }
    };


    const GivePermission = (e) => {
        e.preventDefault();
        setLoading(true);
        let ResultData = [...new Set(existpermission)];
        if (ResultData.length > 0) {
            api.post(`/userPermission/${id}`,
                {
                    role_id: roleData[0]?.roleData[0]._id,
                    user_id: id,
                    permission_id: ResultData,

                })
                .then((response) => {
                    showToast(response.data.message, "success")
                    setLoading(false);
                }).catch((error) => {
                    showToast(error.response.data.errors, 'error');
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }
    const newModules = permissionModules.map((module) => module.name);

    const Modules = [
        "Holiday",
        "Employee",
        "Role",
        "Permission",
        "Project",
        "Task",
        "TimeEntr",
        "Setting",
        "Leave",
        "Billing",
        "Assign Inventory",
        "Creation of Inventories",
        "Master Inventories",
        "CPU master Item",
        "Announcement",
        "Dropdown",
        "Salary",
        "Technology",
        "master permission",
    ];

    const allModules = [...new Set([...Modules, ...newModules])]

    return (
        <>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between flex-wrap">
                            <div className="header-title">
                                <h4 className="card-title mb-0">User Permission</h4>
                            </div>
                            <div>
                                <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab bg-transparent" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                    <Nav.Item as="li" className='d-flex'>
                                        <Nav.Link eventKey="fourth" className='active me-2'><span className='text-capitalize'>Name : {roleData && roleData[0]?.firstname}</span></Nav.Link>
                                        <Nav.Link eventKey="fourth" className='active'><span className='text-capitalize'>Role : {roleData[0]?.roleData[0].role_name}</span></Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </Card.Header>
                        <Card.Body>

                            <div className="col-12">
                                <Card.Body>
                                    <input type='checkbox' className='form-check-input'
                                        onChange={(e) => handleMultiSelect(e)} checked={unChek} />
                                    <span className='mx-2'>{unChek ? 'Deselect All' : 'Select All'}</span>
                                    <Form onSubmit={(e) => GivePermission(e)}>

                                        <div className="col-md-12 mt-1">
                                            <div className="row">
                                                <div className="col-md-12 mt-1">
                                                    <div className="row">
                                                        <div className={`fw-bold col-2  d-flex text-bold align-items-center ${themeMode === 'dark' ? 'border' : 'bDefault'} px-3 py-3`} style={{
                                                            borderColor: themeMode === 'dark' && '#1b202f !important',
                                                            backgroundColor: themeMode === 'dark' ? '#151824' : '#f4f4f4',
                                                        }} >Modules</div>
                                                        <div className={`fw-bold col-10  d-flex justify-content-center align-items-center ${themeMode === 'dark' ? 'border' : 'bDefault'} px-3 py-3`} style={{
                                                            borderColor: themeMode === 'dark' && '#1b202f !important',
                                                            backgroundColor: themeMode === 'dark' ? '#151824' : '#f4f4f4',
                                                        }} >Permissions</div>
                                                        {allModules.map((module) => {

                                                            const matchedPermissions = permission.filter(p => p.permission_name.toLowerCase().includes(module.toLowerCase()));

                                                            return (
                                                                <React.Fragment key={Math.random()}>
                                                                    <div className={`fw-bold col-lg-2 col-md-12  d-flex text-bold align-items-center ${themeMode === 'dark' ? 'border' : 'bDefault'} px-3 py-3`} style={{
                                                                        borderColor: themeMode === 'dark' && '#1b202f !important',
                                                                        backgroundColor: themeMode === 'dark' ? '#151824' : '#f4f4f4',
                                                                    }} >{module}</div>
                                                                    <div className="col-md-10">
                                                                        <div className="row">
                                                                            {matchedPermissions.map((item, index) => {
                                                                                if (module === 'Role' && item.permission_name.includes('RolePermission')) {
                                                                                    return (
                                                                                        <div
                                                                                            className={`col-md-3 ${themeMode === 'dark' ? 'border' : 'bDefault'} 
                                                                                        d-flex align-items-center justify-content-between`}
                                                                                            style={{ borderColor: themeMode === 'light' && '#dee2e6 !important' }} key={index}>

                                                                                            <label style={{ cursor: 'pointer' }} htmlFor={`checkbox-${index}`} className='px-3 py-3'>
                                                                                                {item.permission_name}
                                                                                            </label>

                                                                                            {selectAll || existpermission.includes(item._id) ?
                                                                                                <div style={{ float: "right" }}>
                                                                                                    <input className="form-check-input a" type="checkbox" value={item._id}
                                                                                                        checked={existpermission.includes(item._id) || selectAll}
                                                                                                        onChange={(e) => handlePermissionChange(e)} id={`checkbox-${index}`} />
                                                                                                </div> :
                                                                                                <div style={{ float: "right" }}>
                                                                                                    <input className="form-check-input b" type="checkbox" value={item._id}
                                                                                                        checked={existpermission.includes(item._id)}
                                                                                                        onChange={(e) => handlePermissionChange(e)} id={`checkbox-${index}`} />
                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                else if (module === 'Permission' && item.permission_name.includes('RolePermission')) {

                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <div
                                                                                            className={`col-md-3 ${themeMode === 'dark' ? 'border' : 'bDefault'} 
                                                                                        d-flex align-items-center justify-content-between`}
                                                                                            style={{ borderColor: themeMode === 'light' && '#dee2e6 !important' }} key={index}>
                                                                                            <label style={{ cursor: 'pointer' }} htmlFor={`checkbox-${index}`} className='px-3 py-3'>
                                                                                                {item.permission_name}
                                                                                            </label>
                                                                                            {selectAll || existpermission.includes(item._id) ?
                                                                                                <div style={{ float: "right" }}>
                                                                                                    <input className="form-check-input a" type="checkbox" value={item._id}
                                                                                                        checked={existpermission.includes(item._id) || selectAll}
                                                                                                        onChange={(e) => handlePermissionChange(e)} id={`checkbox-${index}`} />
                                                                                                </div> :
                                                                                                <div style={{ float: "right" }}>
                                                                                                    <input className="form-check-input b" type="checkbox" value={item._id}
                                                                                                        checked={existpermission.includes(item._id)}
                                                                                                        onChange={(e) => handlePermissionChange(e)} id={`checkbox-${index}`} />
                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            })}
                                                                        </div>
                                                                    </div >
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <br />

                                        <div className="text-center">
                                            <Button type="cancel" variant="danger me-2" onClick={() => navigate('/employee')}>Cancel</Button>
                                            <Button type="submit" disabled={loading} variant="primary">{loading ? 'Saving...' : 'Save'}</Button>
                                        </div>
                                    </Form>
                                </Card.Body>


                            </div>
                        </Card.Body>
                    </Card>

                </Col>
            </Row >
            {/* <AdvanceRP  api={api} token={token} id={id}/> */}
        </>
    )
}
export default EmployeePermission;