import React from "react";
import { Route, Routes } from "react-router-dom";
import Index from "../views/dashboard/index";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import TechnologyLists from "../router/ProtectedRouts/TechnologyLists"
import EditEmployee from "../views/Employee/EditEmployee";
import EmployeeList from "../views/Employee/Employee-list";
import JunkEmployee from "../views/Employee/JunkEmployee";
import EmployeeProfile from "../views/Employee/employee-profile";
import AddLeave from "../views/Leaves/AddLeave";
import AllLeaveList from "../views/Leaves/AllLeaveList";
import AddRole from "../views/Role/AddRole";
import EditRole from "../views/Role/EditRole";
import RoleList from "../views/Role/RoleLIst";
import RolePermission from "../views/Role/role-permission";
import AddHoliday from "../views/holiday/AddHoliday";
import EditHoliday from "../views/holiday/EditHoliday";
import HolidayList from "../views/holiday/HolidayList";
import AddPermission from "../views/permission/AddPermission";
import EditPermission from "../views/permission/EditPermission";
import PermissionList from "../views/permission/PermissonList";
import AddProject from "../views/project/AddProject";
import EditProject from "../views/project/EditProject";
import ProjectList from "../views/project/ProjectList";
import AddTask from "../views/task/AddTask";
import EditTask from "../views/task/EditTask";
import TaskList from "../views/task/TaskList";
import { useSelector } from "react-redux";
import AnnouncementsList from "../views/Announcements/AnnouncementsList";
import AddEmployee2 from "../views/Employee/AddEmployee2";
import ChangePassword from "../views/Employee/ChangePassword";
import EditPersonalProfile from "../views/Employee/Edit-personal-profile";
import EmployeePersonalProfile from "../views/Employee/Employee-Personal-profile";
import EmployeePermission from "../views/Employee/EmployeePermission";
import EditLeave from "../views/Leaves/EditLeave";
import LeaveHistory from "../views/Leaves/LeaveHistory";
import LeaveList from "../views/Leaves/Leavelists";
import ActivityLog from "../views/Logs/ActivityLog,";
import EditSalaryStructure from "../views/Salary/EditSalaryStructure";
import EmployeeSalaryList from "../views/Salary/EmployeeSalaryList";
import AddSalaryStructure from "../views/Salary/SalaryStructure";
import SalaryStructureList from "../views/Salary/SalaryStructureList";
import AddSettting from "../views/Setting/AddSettting";
import EditSettting from "../views/Setting/EditSetting";
import ThemeSetting from "../views/Setting/ThemeSetting";
import AddEntry from "../views/TimeEntry/AddEntry";
import AddWorkingHour from "../views/TimeEntry/AddWorkingHour";
import EditEntry from "../views/TimeEntry/EditEntry";
import EditWorkingHour from "../views/TimeEntry/EditWorkingHour";
import EntryList from "../views/TimeEntry/EntryList";
import TimeEntryHistory from "../views/TimeEntry/TimeEntryHistory";
import TimeEntryRequest from "../views/TimeEntry/TimeEntryRequest";
import TimeEntryRequestList from "../views/TimeEntry/TimeEntryRequestList";
import WorkingHourList from "../views/TimeEntry/WorkingHourList";
import InventoryListPage from "../views/inventorySystem/InventoryListPage";
import AddInventory from "../views/inventorySystem/AddInventory";
import MasterItemList from "../views/Master-Item/MasterItemList";
import AddMasterItem from "../views/Master-Item/AddMasterItem";
import AllMasterItem from "../views/MasterItemList/AllMasterItem";
import AddMasterItemInventory from "../views/MasterItemList/AddMasterItemInventory";
import EditMasterItemInventory from "../views/MasterItemList/EditMasterItemInventory";
import AddCPUMasterItem from "../views/AddCPUMasterItem/AddCPUMasterItem";
import AddAllCPUMasterItem from "../views/AddCPUMasterItem/AddAllCPUMasterItem";
import EditAllCPUMasterItem from "../views/AddCPUMasterItem/EditAllCPUMasterItem";
import EditMasterItem from "../views/Master-Item/EditMasterItem";
import DetailsMasterItem from "../views/Master-Item/DetailsMasterItem";
import EditInventory from "../views/inventorySystem/EditInventory";
import ViewProject from "../views/project/ViewProject";
import AddTechnology from "./ProtectedRouts/AddTechnology";
import EditTechnology from "./ProtectedRouts/EditTechnology";
import PermissionModule from "./ProtectedRouts/PermissionModule";
import AddPermissionModule from "./ProtectedRouts/AddPermissionModule";
import EditPermissionModule from "./ProtectedRouts/EditPermissionModule";
import ToastContainerWrapper from "../plugins/ToastContainerWrapper";
import UserLeave from "../views/Leaves/UserLeave";


const DefaultRouter = ({ api, showToast }) => {
  // const api = process.env.REACT_APP_API_KEY;
  const imagePath = process.env.REACT_APP_IMAGE_KEY;
  const userdata = useSelector((state) => state.user.user);
  const user_id = userdata._id;

  return (
    <div>
      <ToastContainerWrapper />
      <TransitionGroup>
        <CSSTransition classNames="fadein" timeout={300}>
          <Routes>
            <Route
              path="*"
              exact
              element={
                <Index
                  api={api}
                  user_id={user_id}
                  user_data={userdata}
                />
              }
            />
            <Route
              path="dashboard/*"
              exact
              element={
                <Index
                  api={api}
                  user_id={user_id}
                  user_data={userdata}
                />
              }
            />
            <Route
              path="change-password"
              exact
              element={
                <ChangePassword
                  api={api}
                  user_id={user_id}
                />
              }
            />
            <Route
              path="activity-log"
              exact
              element={
                <ActivityLog
                  api={api}
                  user_id={user_id}
                  user_data={userdata}
                />
              }
            />

            {/* employee routes*/}
            <Route
              path="employee"
              slug='Employee'
              exact
              element={
                <EmployeeList
                  api={api}
                  user_id={user_id}
                  imagePath={imagePath}
                />
              }
            />
            <Route path="/employee">
              {/* <Route path="add" exact element={<AddEmployee api={api} apiSecretKey={apiSecretKey} token={token} user_id={user_id} />} /> */}
              <Route
                path="add"
                exact
                element={
                  <AddEmployee2
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="profile/:id"
                exact
                element={
                  <EmployeeProfile
                    api={api}
                    user_id={user_id}
                    imagePath={imagePath}
                  />
                }
              />
              <Route
                path="permission/:id"
                exact
                element={
                  <EmployeePermission
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditEmployee
                    api={api}
                    user_id={user_id}
                    imagePath={imagePath}
                  />
                }
              />
              <Route
                path="junk"
                exact
                element={
                  <JunkEmployee
                    api={api}
                    imagePath={imagePath}
                    user_id={user_id}
                  />
                }
              />
            </Route>
            <Route
              path="personal-profile"
              exact
              element={
                <EmployeePersonalProfile
                  api={api}
                  user_id={user_id}
                  imagePath={imagePath}
                />
              }
            />
            <Route path="/personal-profile">
              <Route
                path="edit/:id"
                exact
                element={
                  <EditPersonalProfile
                    api={api}
                    user_id={user_id}
                    imagePath={imagePath}
                  />
                }
              />
            </Route>
            {/* role routes  */}
            <Route
              path="role"
              slug='Role'
              exact
              element={
                <RoleList
                  api={api}
                  user_id={user_id}
                />
              }
            />
            <Route path="/role">
              <Route
                path="add"
                exact
                element={
                  <AddRole
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditRole
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="role-permission/:id/:role_name"
                exact
                element={
                  <RolePermission
                    api={api}
                    user_id={user_id}
                  />
                }
              />
            </Route>
            {/* permission routes  */}
            <Route
              path="permission"
              slug='Permission'
              exact
              element={
                <PermissionList
                  api={api}
                  user_id={user_id}
                />
              }
            />
            <Route path="/permission">
              <Route
                path="add"
                exact
                element={
                  <AddPermission
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditPermission
                    api={api}
                    user_id={user_id}
                  />
                }
              />
            </Route>
            {/* project roures  */}
            <Route
              path="project"
              exact
              slug='Project'
              element={
                <ProjectList
                  user_data={userdata}
                  api={api}
                  user_id={user_id}
                />
              }
            />
            <Route path="/project">
              <Route
                path="add"
                exact
                element={
                  <AddProject
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditProject
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="view/:id"
                exact
                element={
                  <ViewProject
                    api={api}
                    user_id={user_id}
                    user_data={userdata}
                  />
                }
              />
            </Route>
            {/* task routes */}
            <Route
              path="task"
              slug='Task'
              exact
              element={
                <TaskList
                  api={api}
                  user_id={user_id}
                  user_data={userdata}
                />
              }
            />
            <Route path="/task">
              <Route
                path="add"
                exact
                element={
                  <AddTask
                    api={api}
                    user_id={user_id}
                    user_data={userdata}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditTask
                    api={api}
                    user_id={user_id}
                    user_data={userdata}
                  />
                }
              />
            </Route>

            {/* holiday routes   */}
            <Route
              path="holiday"
              exact
              slug='Holiday'
              element={
                <HolidayList
                  api={api}
                  user_id={user_id}
                  showToast={showToast}
                />
              }
            />
            <Route path="/holiday">
              <Route
                path="add"
                exact
                element={
                  <AddHoliday
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditHoliday
                    api={api}
                    user_id={user_id}
                  />
                }
              />
            </Route>
            {/* leaves routes  */}
            {/* <Route path="leaves" exact element={<LeaveList api={api} apiSecretKey={apiSecretKey} token={token} user_id={user_id} />} /> */}
            <Route
              path="leaves"
              slug='Leave'
              exact
              element={
                <LeaveList
                  api={api}
                  user_id={user_id}
                  user_data={userdata}
                />
              }
            />
            <Route
              path="all-requests"
              exact
              element={
                <AllLeaveList
                  api={api}
                  user_id={user_id}
                  user_data={userdata}
                />
              }
            />
            {/* User Leave */}

            <Route
              path="UserLeave"
              exact
              element={
                <UserLeave
                  api={api}
                  user_id={user_id}
                  user_data={userdata}
                />
              }
            />

            <Route path="/leaves">
              <Route
                path="edit/:id/:userID"
                exact
                element={
                  <EditLeave
                    api={api}
                    user_id={user_id}
                    user_data={userdata}
                  />
                }
              />
            </Route>
            <Route
              path="leave-history"
              exact
              slug='Leave-History'
              element={
                <LeaveHistory
                  api={api}
                  user_id={user_id}
                />
              }
            />
            <Route path="/leaves">
              <Route
                path="add"
                exact
                element={
                  <AddLeave
                    api={api}
                    user_id={user_id}
                  />
                }
              />
            </Route>

            {/* time entry routes  */}
            <Route
              path="time-entry"
              slug='TimeEntr'
              exact
              element={
                <EntryList
                  api={api}
                  user_data={userdata}
                />
              }
            />
            <Route
              path="time-entry-history"
              exact
              element={
                <TimeEntryHistory
                  api={api}
                  user_data={userdata}
                />
              }
            />
            <Route path="/time-entry">
              <Route
                path="add"
                exact
                element={
                  <AddEntry
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditEntry
                    api={api}
                    user_id={user_id}
                  />
                }
              />
            </Route>
            <Route
              path="time-entry-date-request"
              exact
              element={
                <TimeEntryRequestList
                  api={api}
                  user_data={userdata}
                />
              }
            />
            <Route path="/time-entry-date-request">
              <Route
                path="request"
                exact
                element={
                  <TimeEntryRequest
                    api={api}
                    user_id={user_id}
                  />
                }
              />
            </Route>

            {/* working hour routes  */}
            <Route
              path="working-hour"
              exact
              element={
                <WorkingHourList
                  api={api}
                  user_data={userdata}
                />
              }
            />
            <Route path="/working-hour">
              <Route
                path="add"
                exact
                element={
                  <AddWorkingHour
                    api={api}
                    user_data={userdata}
                  />
                }
              />
              <Route
                path="edit/:id/:userID"
                exact
                element={
                  <EditWorkingHour
                    api={api}
                    user_data={userdata}
                  />
                }
              />
            </Route>
            {/* setting routes  */}
            <Route
              path="setting"
              exact
              element={
                <ThemeSetting
                  api={api}
                  user_id={user_id}
                  imagePath={imagePath}
                />
              }
            />
            <Route path="/setting">
              <Route
                path="add"
                exact
                element={
                  <AddSettting
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditSettting
                    imagePath={imagePath}
                    api={api}
                    user_id={user_id}
                  />
                }
              />
            </Route>

            {/* inventory System Page Route */}

            <Route
              path="inventory-system"
              exact
              element={
                <InventoryListPage
                  api={api}
                />
              }
            />
            <Route path="/inventory-system">
              <Route
                path="add"
                exact
                element={
                  <AddInventory
                    api={api}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditInventory
                    api={api}
                  />
                }
              />
            </Route>
            <Route
              path="master-item"
              exact
              element={
                <MasterItemList
                  api={api}
                />
              }
            />
            <Route path="/master-item">
              <Route
                path="add"
                exact
                element={
                  <AddMasterItem
                    api={api}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditMasterItem
                    api={api}
                  />
                }
              />
              <Route
                path="Details-master-item"
                exact
                element={
                  <DetailsMasterItem
                    api={api}
                    user_id={user_id}
                    imagePath={imagePath}
                  />
                }
              />
            </Route>
            <Route
              path="Master-Item-List"
              exact
              element={
                <AllMasterItem
                  api={api}
                />
              }
            />
            <Route path="/Master-Item-List">
              <Route
                path="add"
                exact
                element={
                  <AddMasterItemInventory
                    api={api}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditMasterItemInventory
                    api={api}
                    user_id={user_id}
                  />
                }
              />
            </Route>
            <Route
              path="Add-CPU-Master-Item"
              exact
              element={
                <AddCPUMasterItem
                  api={api}
                />
              }
            />
            <Route path="/Add-CPU-Master-Item">
              <Route
                path="add"
                exact
                element={
                  <AddAllCPUMasterItem
                    api={api}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditAllCPUMasterItem
                    api={api}
                    user_id={user_id}
                  />
                }
              />
            </Route>

            {/* salary routes  */}

            <Route
              path="salary-structure"
              exact
              element={
                <SalaryStructureList
                  api={api}
                  user_id={user_id}
                />
              }
            />
            <Route
              path="employee-salary"
              exact
              element={
                <EmployeeSalaryList
                  api={api}
                  user_data={userdata}
                />
              }
            />
            <Route path="/salary-structure">
              <Route
                path="add"
                exact
                element={
                  <AddSalaryStructure
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="edit/:id"
                exact
                element={
                  <EditSalaryStructure
                    api={api}
                    user_id={user_id}
                  />
                }
              />
            </Route>

            {/* announcement */}
            <Route
              path="announcement"
              exact
              element={
                <AnnouncementsList
                  api={api}
                  user_id={user_id}
                />
              }
            />

            {/* Technology */}
            <Route
              path="/technology"
              exact
              element={
                <TechnologyLists
                  api={api}
                  user_id={user_id}
                />
              }
            />
            <Route path="/technology">
              <Route
                path="add"
                exact
                element={
                  <AddTechnology
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                // path="edit/:id"
                path="edit"
                exact
                element={
                  <EditTechnology
                    api={api}
                    user_id={user_id}
                  />
                }
              />
            </Route>

            {/* Permission - Slug */}
            <Route
              path="/permission-module"
              exact
              element={
                <PermissionModule
                  api={api}
                  user_id={user_id}
                />
              }
            />
            <Route path="/permission-module">
              <Route
                path="add"
                exact
                element={
                  <AddPermissionModule
                    api={api}
                    user_id={user_id}
                  />
                }
              />
              <Route
                path="edit"
                exact
                element={
                  <EditPermissionModule
                    api={api}
                    user_id={user_id}
                  />
                }
              />

            </Route>

          </Routes>
        </CSSTransition>
      </TransitionGroup >
    </div>

  );
};

export default DefaultRouter;
