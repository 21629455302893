import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Card from '../../components/Card'
import { PermissionData } from '../../layouts/dashboard/default'

const WorkingHourList = ({ api, user_data }) => {

    const Permissions = useContext(PermissionData);
    const themeData = useSelector((state) => state.mode.colorcustomizer);
    const [loading, setLoading] = useState(false);
    const [totalColor, setTotalColor] = useState('');
    const [workingHourData, setWorkingHourData] = useState([]);
    const [breakHourData, setBreakHourData] = useState([]);
    const [totalWrokingHour, setTotalWrokingHour] = useState('');
    const [totalBreakHour, setTotalBreakHour] = useState('');
    const [userId, setUserId] = useState('');
    const [date, setDate] = useState('');
    const [cancelToken, setCancelToken] = useState(null);
    const [show, setShow] = useState(false);
    const [deleteid, setDeleteid] = useState('');
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        if (themeData === 'theme-color-default') {
            setTotalColor('#2e46ba');
        }
        else if (themeData === 'theme-color-blue') {
            setTotalColor('#009cc7');
        }
        else if (themeData === 'theme-color-gray') {
            setTotalColor('#91969e');
        }
        else if (themeData === 'theme-color-red') {
            setTotalColor('#af424f');
        }
        else if (themeData === 'theme-color-yellow') {
            setTotalColor('#bb550e');
        }
        else if (themeData === 'theme-color-pink') {
            setTotalColor('#b76b8f');
        }
        return () => { };
    }, [themeData]);

    useEffect(() => {
        const today = new Date();
        const day = today.getDate().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear();
        const todayDate = `${year}-${month}-${day}`;
        setDate(todayDate)
        return () => {
        };
    }, []);

    useEffect(() => {
        let userID = user_data._id;
        setUserId(userID);
        return () => {
        };
    }, []);

    const WorkingHourListing = async (dates) => {
        if (cancelToken) {
            cancelToken.cancel('New request initiated');
        }
        const source = axios.CancelToken.source();
        setCancelToken(source);
        try {
            const response = await api.post(`/getWorkingHourByday`, {
                user_id: userId,
                date: date,
            }
            );
            if (response.data !== undefined) {
                setWorkingHourData(response.data.workingHourData);
                setUserList(response.data.userData);
                setWorkingHourData(response.data.workingHourData);
                setBreakHourData(response.data.breakData);
            }
        } catch (error) {
            console.log("error", error)
        }
    };

    useEffect(() => {
        WorkingHourListing(date);
        return () => {
        };
    }, [date, userId]);

    const Handledelete = () => {
        const id = deleteid;
        setLoading(true);
        if (id !== undefined) {
            api.post(`/deleteWorkingHour/${id}`)
                .then(() => {
                    setShow(false);
                    WorkingHourListing();
                }).catch((error) => {
                    setLoading(false);
                    console.log("error", error)
                });
        }
        setLoading(false);
    }

    const handleClose = () => {
        setShow(false);
    }

    function deletemodel(id) {
        setDeleteid(id);
        setShow(true);
    }

    useEffect(() => {
        const totalMinutes = workingHourData.reduce((acc, curr) => {
            const [hours, minutes] = curr.total_hour.split(":");
            const timeInMinutes = parseInt(hours) * 60 + parseInt(minutes);
            return acc + timeInMinutes;
        }, 0);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        setTotalWrokingHour(hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0'));
        const totalBreak = breakHourData.reduce((acc, curr) => {
            const [breakhours, breakminutes] = curr.break.split(":");
            const breaktimeInMinutes = parseInt(breakhours) * 60 + parseInt(breakminutes);
            return acc + breaktimeInMinutes;
        }, 0);
        const breakhours = Math.floor(totalBreak / 60);
        const breakminutes = totalBreak % 60;
        setTotalBreakHour(breakhours.toString().padStart(2, '0') + ':' + breakminutes.toString().padStart(2, '0'));
        return () => {
        };
    }, [workingHourData, breakHourData]);

    return (
        <>
            <Row>
                <Modal show={show} onHide={handleClose} className='text-center'>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "5rem" }}>Do you want to delete it ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button variant="primary" disabled={loading} onClick={() => Handledelete()}>{loading ? 'Deleting...' : 'Delete'}</Button>
                        <Button variant="danger" onClick={() => handleClose()} value="Cancel" >Cancel</Button>
                    </Modal.Body>
                </Modal>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Working Hours</h4>
                            </div>
                            <div className="entry-button d-flex justify-content-end">
                                {Permissions.includes('Add TimeEntry') &&
                                    <div className="Add-Entries me-2">
                                        <Link to={{ pathname: 'add', state: { from: '/time-entry' } }}> <h4 className="btn btn-primary">
                                            <i className="fa-solid fa-plus" style={{ marginRight: "5px" }}></i>
                                            Add Hours</h4>
                                        </Link>
                                    </div>
                                }
                            </div>
                        </Card.Header>

                        <Card.Header className={`d-flex justify-content-between`}>
                            <div className="row align-items-center w-100">
                                <Col sm="12" lg="3">
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="permission_description">Date</Form.Label>
                                        <Form.Control type="date" id="project_description" onChange={e => setDate(e.target.value)} required
                                            value={date}
                                            max={new Date().toISOString().substr(0, 10)}
                                        />
                                    </Form.Group>
                                </Col>
                                {(Permissions.includes("View Dropdown")) && (
                                    <Col sm="12" lg="3">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="permission_description">Employee</Form.Label>
                                            <select
                                                name="user-list-table_length" aria-controls="user-list-table"
                                                className="form-select form-select"
                                                onChange={(e) => setUserId(e.target.value)} defaultValue={user_data._id}>
                                                {userList && userList.map((item, index) => (
                                                    <option value={item._id} key={index}>{item.firstname + ' ' + item.last_name}</option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                )}
                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table id="user-list-table" className="table table-striped" role="grid" data-toggle="data-table">
                                    <thead>
                                        <tr className="light">
                                            <th>
                                                <span className="title">Start Time</span>
                                            </th>
                                            <th>
                                                <span className="title">End Time</span>
                                            </th>
                                            <th>
                                                <span className="title" >Total</span>
                                            </th>
                                            <th min-width="100px">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-capitalize'>
                                        {workingHourData?.length > 0 ?
                                            workingHourData?.map((item, index) => (
                                                <tr key={index} className="border-light">
                                                    <td>{item.start_time}</td>
                                                    <td>{item.end_time}</td>
                                                    <td>{item.total_hour}</td>
                                                    <td>
                                                        <div className="flex align-items-center list-user-action">
                                                            {Permissions.includes('Update TimeEntry') &&
                                                                <Link className="btn btn-sm btn-icon btn-warning" style={{ marginRight: "5px" }} data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={`edit/${item._id}/${userId}`}>
                                                                    <span className="btn-inner">
                                                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </span>
                                                                </Link>
                                                            }
                                                            {Permissions.includes('Delete TimeEntry') &&
                                                                <Link onClick={() => deletemodel(item._id)} className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to='#'>
                                                                    <span className="btn-inner">
                                                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                                            <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </span>
                                                                </Link>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan={99} className='text-center'>
                                                    No Data Found
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                    <thead>
                                        <tr className="light">
                                            <th style={{ color: totalColor }}>Total</th>
                                            <th></th>
                                            <th style={{ color: totalColor }}>{totalWrokingHour}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Break Hours</h4>
                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table id="user-list-table" className="table table-striped" role="grid" data-toggle="data-table">
                                    <thead>
                                        <tr className="light">
                                            <th>
                                                <span className="title">Start Time</span>
                                            </th>
                                            <th>
                                                <span className="title">End Time</span>
                                            </th>
                                            <th>
                                                <span className="title">Total</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-capitalize'>
                                        {breakHourData?.length > 0 ?
                                            breakHourData?.map((item, index) => (
                                                <tr key={index} className="border-light">
                                                    <td>{item.start_time}</td>
                                                    <td>{item.end_time}</td>
                                                    <td>{item.break}</td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan={99} className='text-center'
                                                >No Data Found
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                    <thead>
                                        <tr className="light">
                                            <th style={{ color: totalColor }}>Total</th>
                                            <th></th>
                                            <th style={{ color: totalColor }}>{totalBreakHour}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col >
            </Row >
        </>
    )
}

export default WorkingHourList;